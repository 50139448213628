import { initializeApp } from 'firebase/app';
import * as Sentry from '@sentry/browser';

import '@/i18n-init';
import { searchParamKeys, setQueryParam } from '@/shares/dataManager';

const isLocal = import.meta.env.VITE_NODE_ENV === 'local';

Sentry.init({
  dsn: isLocal
    ? ''
    : 'https://4808a36b55b3a0b1f2116a7dcd9fb8b7@sentry.camerafi.com/7',
  environment: import.meta.env.VITE_NODE_ENV,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 1.0,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);

if (window.location.search) {
  const searchParams = new URLSearchParams(window.location.search);

  searchParamKeys.forEach((key) => {
    const value = searchParams.get(key);
    if (value) {
      setQueryParam(key, key === 'callbackUrl' ? new URL(value) : value);
    }
  });
}

//TODO: 추후 삭제될 코드. LOG-129
(function () {
  const originalSetTimeout = window.setTimeout;
  window.setTimeout = function (fn, delay, ...args) {
    // Check if the delay matches Firebase's _Timeout.AUTH_EVENT
    if (delay === 8000) {
      delay = 2000;
    }
    return originalSetTimeout(fn, delay, ...args);
  };
})();

import { signInMethodType } from '@/types/auth';
import { OAuthCredential } from '@firebase/auth-types';

export interface dataTypes {
  credentialForLinkAccount: OAuthCredential | null;
  queries: {
    api: string | null;
    callbackUrl: URL | null;
    idToken: string | null;
    platform: string | null;
    refreshToken: string | null;
    signUpEmail: string | null;
    uid: string | null;
    userName: string | null;
    linkedMethod: signInMethodType | null;
    traceparent: string | null;
  };
}

const sharedData: dataTypes = {
  credentialForLinkAccount: null,
  queries: {
    api: null,
    callbackUrl: null,
    idToken: null,
    platform: null,
    refreshToken: null,
    signUpEmail: null,
    uid: null,
    userName: null,
    linkedMethod: null,
    traceparent: null,
  },
};

const searchParamKeys: Array<keyof dataTypes['queries']> = [
  'api',
  'callbackUrl',
  'idToken',
  'platform',
  'refreshToken',
  'signUpEmail',
  'uid',
  'userName',
  'linkedMethod',
  'traceparent',
];

const setCredentialForLinkAccount = (
  credential: dataTypes['credentialForLinkAccount'],
) => {
  sharedData.credentialForLinkAccount = credential;
};

const setQueryParam = <K extends keyof dataTypes['queries']>(
  key: K,
  value: dataTypes['queries'][K],
) => {
  sharedData.queries[key] = value;
};

export {
  searchParamKeys,
  sharedData,
  setQueryParam,
  setCredentialForLinkAccount,
};

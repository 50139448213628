const isHTMLElement = (element: Element | null): element is HTMLElement => {
  return element instanceof HTMLElement;
};

const isInputElement = (
  element: Element | null,
): element is HTMLInputElement => {
  return element instanceof HTMLInputElement;
};

const isButtonElement = (
  element: Element | null,
): element is HTMLButtonElement => {
  return element instanceof HTMLButtonElement;
};

const isImageElement = (
  element: Element | null,
): element is HTMLImageElement => {
  return element instanceof HTMLImageElement;
};

const isAnchorElement = (
  element: Element | null,
): element is HTMLAnchorElement => {
  return element instanceof HTMLAnchorElement;
};

export {
  isAnchorElement,
  isImageElement,
  isHTMLElement,
  isInputElement,
  isButtonElement,
};
